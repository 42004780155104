import React, { useCallback, useEffect, useRef, useState } from "react"
import ArrowIcon from "../planning/arrowIcon"
import TextArea from "./TextArea"
import Input from "./Input"
import Select from "./Select"
// import CoordinateItem from "./CoordinateItem"
import { uid } from "uid"
import fetchQuery from "../../utils/fetchQuery"
import DeleteModal from "./deleteModal"
import PropTypes from "prop-types"
import { Collapse, Form } from "react-bootstrap"

const formatCoordinates = coordinates => {
    return coordinates?.map(c => ({ ...c, id: uid(32) }))
}

const VesselPriceItem = ({ vessels, price, vesselId, setCardData, vesselName }) => {
    const currentVessel = vessels.find(v => v.id === vesselId)

    const onChange = e => {
        setCardData(data => {
            const copyPrices = [...data.prices]
            const vesselIndex = copyPrices.findIndex(v => v.vesselId === vesselId)

            copyPrices[vesselIndex].price = +e.target.value

            return {
                ...data,
                prices: [...copyPrices]
            }
        })
    }
    return (
        <div className="col-12 configuration-card__vessel-price-item d-flex align-items-center">
            <div className="col-5">{currentVessel?.vesselName}</div>
            <div className="col-7">
                <Input
                    type="number"
                    title="Price"
                    dimension="€"
                    value={!price ? undefined : price}
                    header={false}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

const ConfigurationCard = ({ type, data, onDeleteCard, locations, vessels }) => {
    const [cardData, setCardData] = useState(data ?? {})
    const [isOpen, setIsOpen] = useState(data?.isOpen)
    const [coordinates, setCoordinates] = useState(formatCoordinates(data?.coordinates) ?? [])
    const [showModal, setShowModal] = useState(false)
    const [incorrectGoogleLink, setIncorrectGoogleLink] = useState(null)

    useEffect(() => {
        if (!coordinates.length) {
            setCoordinates([
                {
                    id: uid(32),
                    latitude: "",
                    longitude: ""
                }
            ])
        }
    }, [coordinates.length])

    useEffect(() => {
        if (coordinates[coordinates.length - 1]?.latitude || coordinates[coordinates.length - 1]?.longitude) {
            setCoordinates([
                ...coordinates,
                {
                    id: uid(32),
                    latitude: "",
                    longitude: ""
                }
            ])
        }
    }, [coordinates[coordinates.length - 1]?.latitude, coordinates[coordinates.length - 1]?.longitude])

    const setActiveCategory = category => {
        setCardData({ ...cardData, category })
    }

    const setCoordinate = (e, name, index) => {
        setCoordinates([
            ...coordinates.slice(0, index),
            {
                ...coordinates[index],
                [`${name}`]: +e.target.value
            },
            ...coordinates.slice(index + 1)
        ])
    }

    const onDeleteCoordinate = index => {
        const copyCoordinates = [...coordinates]
        copyCoordinates.splice(index, 1)
        setCoordinates(copyCoordinates)
    }

    const yachtsContent = type === "yachts"
    const routesContent = type === "routes"
    const extrasContent = type === "extras"

    const toggleActive = useCallback(async () => {
        const endPoint = yachtsContent
            ? "configuration/Vessel/ToggleVesselActive"
            : routesContent
            ? "configuration/Route/ToggleRouteActive"
            : "configuration/Extra/ToggleExtraActive"
        if (cardData.id) {
            await fetchQuery({
                method: "POST",
                endPoint,
                toJSON: false,
                body: cardData.id,
                successCallback: response => {
                    if (response.ok) {
                        setCardData(d => ({ ...d, isActive: !d.isActive }))
                    }
                }
            })
        } else {
            setCardData(d => ({ ...d, isActive: !d.isActive }))
        }
    }, [cardData.isActive, cardData.id])

    const onSaveCard = async () => {
        const endPointCreate = routesContent ? "configuration/Route/CreateRoute" : "configuration/Extra/CreateExtra"
        const endpointUpdate = yachtsContent
            ? "configuration/Vessel/EditVesselDetails"
            : routesContent
            ? "configuration/Route/EditRouteDetails"
            : "configuration/Extra/EditExtraDetails"

        const filledCoordinates = coordinates.filter(c => c.latitude !== "" || c.longitude !== "")

        if (cardData.new) {
            await fetchQuery({
                method: "POST",
                endPoint: endPointCreate,
                body: {
                    ...cardData,
                    isOpen: undefined,
                    new: undefined,
                    uid: undefined,
                    googleMapsUrl: !cardData?.googleMapsUrl?.length ? undefined : cardData.googleMapsUrl,
                    price: !routesContent ? cardData.price : undefined,
                    maxQuantity: cardData?.category === "Others" ? cardData?.maxQuantity : undefined,
                    coordinates: routesContent
                        ? filledCoordinates.map(({ latitude, longitude }) => ({ latitude, longitude }))
                        : undefined,
                    prices: routesContent ? cardData.prices.map(p => ({ vesselId: p.vesselId, price: p.price })) : undefined
                },
                toJSON: false,
                successCallback: async response => {
                    const id = await response.text()
                    setCardData(d => ({ ...d, id }))
                }
            })
        } else {
            await fetchQuery({
                method: "POST",
                endPoint: endpointUpdate,
                body: {
                    ...cardData,
                    uid: undefined,
                    googleMapsUrl: !cardData?.googleMapsUrl?.length ? undefined : cardData.googleMapsUrl,
                    maxQuantity: cardData?.category === "Others" ? cardData?.maxQuantity : undefined,
                    coordinates: routesContent
                        ? filledCoordinates.map(({ latitude, longitude }) => ({ latitude, longitude }))
                        : undefined
                },
                toJSON: false,
                successCallback: response => {}
            })
        }
        setIsOpen(false)
    }

    const notValidGoogleLink = incorrectGoogleLink
    const emptyRoutePrice = cardData.prices?.some(p => !p.price)
    const isDisabledSave = yachtsContent
        ? !cardData.yachtName
        : routesContent
        ? !cardData.routeName || emptyRoutePrice || notValidGoogleLink
        : !cardData.extraName

    const onChangeGoogleLink = e => {
        setCardData(d => ({ ...d, googleMapsUrl: e.target.value }))
        if (e.target.value.length) {
            const match = e.target.value.match(/https:\/\/www\.google\.com\/maps\/d\/embed\?[^"]+/gm)

            if (!match) setIncorrectGoogleLink("Link is not valid")
            else setIncorrectGoogleLink(null)
        } else {
            setIncorrectGoogleLink(null)
        }
    }

    return (
        <div className="col-12 configuration-card">
            <div className={`configuration-card__header position-relative ${isOpen ? "open" : ""}`}>
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <p className="mb-0 configuration-card__header_name">
                        {cardData.vesselName ?? cardData.routeName ?? cardData.extraName}
                    </p>
                    <div className="d-flex align-items-center">
                        <label className="switch">
                            <input checked={cardData.isActive} onChange={toggleActive} type="checkbox" />
                            <span className="slider round"></span>
                        </label>
                        <label className="status d-none d-md-block">{cardData.isActive ? "Active" : "Inactive"}</label>
                    </div>
                </div>
                <div
                    aria-expanded={isOpen}
                    aria-controls={`expense_${cardData.id}`}
                    onClick={() => setIsOpen(!isOpen)}
                    className={`configuration-card_arrow ${isOpen ? "open" : ""} position-absolute`}
                >
                    <ArrowIcon color="#3D4998" />
                </div>
            </div>
            <Collapse in={isOpen ?? false}>
                <div id={`expense_${cardData.id}`}>
                    <div className={`row configuration-card__body`}>
                        {yachtsContent ? (
                            <>
                                <div className="col-12 col-md-9">
                                    <TextArea
                                        onChange={e => setCardData({ ...cardData, description: e.target.value })}
                                        value={cardData.description}
                                        title="Description"
                                    />
                                </div>
                                <div className="col-12 col-md-3 ps-md-5">
                                    <div className="col-12 mt-3 mt-md-0">
                                        <Input
                                            type="number"
                                            title="Max persons"
                                            value={cardData.capacity}
                                            onChange={e => setCardData({ ...cardData, capacity: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-12 mt-3">
                                        <div className="d-flex flex-column">
                                            <div className="select-name">Location</div>
                                            <Form.Select
                                                className="select-location"
                                                value={cardData.location}
                                                onChange={e =>
                                                    setCardData({
                                                        ...cardData,
                                                        location: e.target.value === "Select" ? "" : e.target.value
                                                    })
                                                }
                                            >
                                                {locations.map(l => (
                                                    <option value={l} key={l}>
                                                        {l}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : routesContent ? (
                            <>
                                <div className="configuration-card__subblock col-12 col-xl-6 d-flex flex-wrap">
                                    <div className="col-12 col-xl-3">
                                        <p className="configuration-card__subtitle">General</p>
                                    </div>
                                    <div className="col-12 col-xl-9">
                                        <div className="col-12 ">
                                            <div className="col-12">
                                                <Input
                                                    required
                                                    type="text"
                                                    title="Route name"
                                                    value={cardData.routeName}
                                                    onChange={e => setCardData({ ...cardData, routeName: e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 mt-3">
                                                <TextArea
                                                    title="Description"
                                                    value={cardData.description}
                                                    onChange={e => setCardData({ ...cardData, description: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 pe-xl-0">
                                            <div className="col-12 mt-3 ">
                                                <Input
                                                    type="number"
                                                    inputStyle={{ paddingRight: "40px" }}
                                                    title="Amount of time"
                                                    dimension="hours"
                                                    value={+cardData.hoursLength}
                                                    onChange={e => setCardData({ ...cardData, hoursLength: +e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 mt-3 mb-3 mb-xl-0">
                                                <div className="d-flex flex-column">
                                                    <div className="select-name">Location</div>
                                                    <Form.Select
                                                        className="select-location"
                                                        value={cardData.location}
                                                        onChange={e =>
                                                            setCardData({
                                                                ...cardData,
                                                                location: e.target.value === "Select" ? "" : e.target.value
                                                            })
                                                        }
                                                    >
                                                        {locations.map(l => (
                                                            <option value={l} key={l}>
                                                                {l}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="col-12 pe-xl-0">
                                                <div className="col-12 mt-3 ">
                                                    <Input
                                                        type="text"
                                                        title="Google Maps link"
                                                        value={cardData?.googleMapsUrl}
                                                        onChange={onChangeGoogleLink}
                                                        notValid={incorrectGoogleLink}
                                                        popoverContent={
                                                            <p className="google-link-popover">
                                                                Create a route map in Google Maps, generate a link to it, and paste it
                                                                here.{" "}
                                                                <a
                                                                    href="https://support.google.com/mymaps/topic/3024924?hl=en"
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    className="google-link-popover__learn-more"
                                                                >
                                                                    Learn more
                                                                </a>
                                                            </p>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6 d-flex flex-wrap">
                                    <div className="configuration-card__price-title col-12 col-xl-3 text-start text-xl-center">
                                        <p className="configuration-card__subtitle">Price</p>
                                    </div>
                                    <div className="col-12 col-xl-9">
                                        <div className="row gx-0">
                                            <div className="col-5 text-title">Yacht</div>
                                            <div className="col-7 text-title">Price</div>
                                        </div>
                                        {data.prices
                                            ?.map(d => ({ ...d, vesselName: vessels.find(v => v.id === d.vesselId)?.vesselName }))
                                            .sort((a, b) => a.vesselName?.localeCompare(b.vesselName))
                                            .map(p => (
                                                <VesselPriceItem key={p.vesselId} vessels={vessels} {...p} setCardData={setCardData} />
                                            ))}
                                    </div>
                                </div>
                            </>
                        ) : (
                            extrasContent && (
                                <>
                                    <div className="col-12 col-md-8 pe-md-5">
                                        <div className="col-12">
                                            <Input
                                                required
                                                type="text"
                                                title="Extra name"
                                                value={cardData.extraName}
                                                onChange={e => setCardData({ ...cardData, extraName: e.target.value })}
                                            />
                                        </div>
                                        <div className="col-12 mt-3">
                                            <TextArea
                                                title="Description"
                                                value={cardData.description}
                                                onChange={e => setCardData({ ...cardData, description: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 d-md-flex">
                                        <div className="col-12 col-md-6 pe-md-5 d-md-block">
                                            <div className="col-12 mt-3 mt-md-0">
                                                <Input
                                                    type="number"
                                                    title="Price"
                                                    dimension="€"
                                                    value={+cardData.price}
                                                    onChange={e => setCardData({ ...cardData, price: +e.target.value })}
                                                />
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="d-flex flex-column">
                                                    <div className="select-name">Location</div>
                                                    <Form.Select
                                                        className="select-location"
                                                        value={cardData.location}
                                                        onChange={e =>
                                                            setCardData({
                                                                ...cardData,
                                                                location: e.target.value === "Select" ? "" : e.target.value
                                                            })
                                                        }
                                                    >
                                                        {locations.map(l => (
                                                            <option value={l} key={l}>
                                                                {l}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                {cardData.category === "Others" && (
                                                    <Input
                                                        type="number"
                                                        title="Max quantity"
                                                        value={!cardData?.maxQuantity ? undefined : cardData?.maxQuantity}
                                                        onChange={e =>
                                                            setCardData({
                                                                ...cardData,
                                                                maxQuantity: !e.target.value ? undefined : +e.target.value
                                                            })
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 mt-3 mt-md-0">
                                            <Select
                                                setActiveCategory={setActiveCategory}
                                                selected={cardData.category}
                                                title="Category"
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                </div>
            </Collapse>
            {isOpen && (
                <div
                    className={`col-12  configuration-card__buttons d-flex ${
                        !yachtsContent ? "justify-content-between" : "justify-content-end"
                    } align-items-center`}
                >
                    {!yachtsContent && (
                        <button className="btn" type="button" onClick={() => setShowModal(true)}>
                            DELETE
                        </button>
                    )}
                    <button disabled={!yachtsContent && isDisabledSave} className="btn" type="button" onClick={onSaveCard}>
                        SAVE
                    </button>
                </div>
            )}
            {showModal && (
                <DeleteModal setShowModal={setShowModal} newCard={cardData?.new} onDeleteCard={onDeleteCard} id={cardData.id} />
            )}
        </div>
    )
}

ConfigurationCard.propTypes = {
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    onDeleteCard: PropTypes.func.isRequired
}

export default ConfigurationCard
