import React from "react"
import PropTypes from "prop-types"

const DeleteModal = ({ setShowModal, onDeleteCard, id, newCard }) => {
    return (
        <div className="modal d-block modal-edit">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content px-3">
                    <div className="modal-body">
                        <p className="mb-0">Delete this item?</p>
                    </div>
                    <div className="modal-footer pb-2">
                        <button type="button" className="btn btn-cancel" onClick={() => setShowModal(false)}>
                            cancel
                        </button>
                        <button onClick={() => onDeleteCard(id, newCard)} className="button fill dark">
                            <span>delete</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

DeleteModal.propTypes = {
    setShowModal: PropTypes.func.isRequired,
    onDeleteCard: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    newCard: PropTypes.bool
}

export default DeleteModal
