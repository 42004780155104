import React from "react"
import PropTypes from "prop-types"

const TextArea = ({ title, value, onChange }) => {
    return (
        <div className="custom-textarea d-flex flex-column">
            <label>{title}</label>
            <textarea onChange={onChange} value={value ?? undefined} />
        </div>
    )
}

TextArea.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default TextArea
