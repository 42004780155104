import { Link } from "gatsby"
import React, { useCallback, useEffect, useState } from "react"
import { uid } from "uid"
import { ArrayParam, NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import { CARDS_PER_PAGE } from "../../enums/screenWidth"
import { getRows } from "../../utils/bookingQueries"
import fetchQuery from "../../utils/fetchQuery"
import FilterSelect from "../bookings/filterSelect"
import PaginationComponent from "../bookings/pagination"
import ConfigurationCard from "./configurationCard"

const ConfigurationContent = () => {
    const [query, setQuery] = useQueryParams({
        t: withDefault(StringParam, "yachts"),
        s: withDefault(ArrayParam, []),
        page: withDefault(NumberParam, 1)
    })
    const [cards, setCards] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [loadMore, setLoadMore] = useState(false)
    const [failMessage, setFailMessage] = useState("")
    const [locations, setLocations] = useState([])
    const [vessels, setVessels] = useState([])
    const [refetch, setRefetch] = useState(false)

    const { t: type, s: status, page } = query

    const options = [
        {
            id: "active",
            name: "Active"
        },
        {
            id: "inactive",
            name: "Inactive"
        }
    ]

    const getLocations = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/Location/GetLocationSelectList",
            successCallback: response => {
                setLocations(response)
            }
        })
    }

    const getVessels = async () => {
        await fetchQuery({
            method: "GET",
            endPoint: "configuration/Vessel/GetVesselList",
            successCallback: response => {
                setVessels(response.items)
            }
        })
    }

    const getItems = async (typeItem, statusItem, page) => {
        setCards([])
        const { startRow, endRow } = getRows(totalCount, page)

        const status =
            !statusItem?.length || statusItem.length > 1 ? "" : statusItem[0] === "active" ? "&status=true" : "&status=false"
        const endPoint =
            typeItem === "yachts"
                ? `configuration/Vessel/GetVesselList?${startRow ? `startRow=${startRow}` : ""}&endRow=${endRow}${status}`
                : typeItem === "routes"
                ? `configuration/Route/GetRouteList?${startRow ? `startRow=${startRow}` : ""}&endRow=${endRow}${status}`
                : `configuration/Extra/GetExtraList?${startRow ? `startRow=${startRow}` : ""}&endRow=${endRow}${status}`

        await fetchQuery({
            method: "GET",
            endPoint,
            successCallback: response => {
                setFailMessage("")
                setCards(response.items)
                setTotalCount(response.totalCount)
            },
            failCallback: () => {
                setFailMessage("Not Found.")
            }
        })
    }

    const onDeleteCard = useCallback(
        async (id, newCard) => {
            const endPoint =
                type === "routes" ? "configuration/Route/DeleteRoute" : type === "extras" && "configuration/Extra/DeleteExtra"

            if (id) {
                await fetchQuery({
                    method: "POST",
                    endPoint,
                    body: id,
                    toJSON: false,
                    successCallback: response => {
                        if (response.ok) {
                            setCards(cardss => [...cardss.filter(c => c.id !== id)])
                            setTotalCount(c => c - 1)

                            if (cards.length - 1 === 0 && page - 1 !== 0) {
                                setQuery({ page: page - 1 })
                            } else {
                                setRefetch(true)
                            }
                        }
                    }
                })
            } else {
                setCards([...cards.filter(c => c.id !== id)])
                if (cards.length - 1 === 0 && page - 1 !== 0) {
                    setQuery({ page: page - 1 })
                } else {
                    setRefetch(true)
                }
            }
        },
        [cards]
    )

    const onAddCard = () => {
        if (type === "routes") {
            const vesselsPriceObject = vessels.reduce((arr, vessel) => {
                return [
                    ...arr,
                    {
                        vesselId: vessel.id,
                        price: undefined
                    }
                ]
            }, [])

            setCards([
                {
                    uid: uid(32),
                    routeName: "New route",
                    location: locations[0] ?? "Ibiza",
                    prices: [...vesselsPriceObject],
                    googleMapsUrl: "",
                    hoursLength: 0,
                    description: "",
                    coordinates: [],
                    isActive: false,
                    isOpen: true,
                    new: true
                },
                ...(cards.length === 10 ? cards.slice(0, 9) : cards)
            ])
        } else if (type === "extras") {
            setCards(c => [
                {
                    uid: uid(32),
                    extraName: "New extra",
                    location: locations[0] ?? "Ibiza",
                    category: "Food",
                    price: 0,
                    maxQuantity: undefined,
                    description: "",
                    isActive: false,
                    isOpen: true,
                    new: true
                },
                ...(c.length === 10 ? c.slice(0, 9) : c)
            ])
        }
    }

    useEffect(() => {
        if (!totalCount && page !== 1) setQuery({ page: 1 })
        else {
            getItems(type, status, page)
        }
    }, [type, status, page])

    useEffect(() => {
        if (refetch && !loadMore) {
            getItems(type, status, page)
            setRefetch(false)
        }
    }, [type, status, page, refetch])

    useEffect(() => {
        if (type === "routes") {
            getVessels()
        }
        getLocations()
    }, [type])

    return (
        <div className="container-fluid container-xxl configuration">
            <div className="row configuration-wrapper-paddingy">
                <div className="col-12 configuration__header">
                    <h1>Configuration</h1>
                </div>
                <div className="col-12 configuration__nav">
                    <nav>
                        <ul>
                            <li>
                                <Link className={`${type === "yachts" ? "active" : ""}`} to="/configuration?t=yachts">
                                    Yachts
                                </Link>
                            </li>
                            <li>
                                <Link className={`${type === "routes" ? "active" : ""}`} to="/configuration?t=routes">
                                    Routes
                                </Link>
                            </li>
                            <li>
                                <Link className={`${type === "extras" ? "active" : ""}`} to="/configuration?t=extras">
                                    Extras
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="col-12 configuration__filter d-flex align-items-center justify-content-between">
                    <FilterSelect
                        className="configuration__filter_select"
                        selectedOptions={status}
                        options={options}
                        placeholder="Status"
                        name="s"
                        setQuery={setQuery}
                    />

                    {type !== "yachts" && (
                        <button className="btn configuration__filter_add" onClick={onAddCard}>
                            + ADD ITEM
                        </button>
                    )}
                </div>

                <div className="col-12">
                    {cards?.map(c => (
                        <ConfigurationCard
                            key={c?.id || c?.uid}
                            vessels={vessels}
                            data={c}
                            type={type}
                            locations={locations}
                            onDeleteCard={onDeleteCard}
                        />
                    ))}
                </div>
                {!cards.length && <div className="p-5 d-flex justify-content-center align-items-center">Not Found</div>}

                {failMessage && !cards.length ? (
                    <div className="p-5 d-flex justify-content-center align-items-center">{failMessage}</div>
                ) : null}

                {typeof window !== "undefined" && (
                    <div className="custom__pagination mt-4">
                        <PaginationComponent
                            itemsCount={totalCount}
                            itemsPerPage={CARDS_PER_PAGE}
                            currentPage={page}
                            setQuery={setQuery}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ConfigurationContent
