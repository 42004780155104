import React from "react"
import PropTypes from "prop-types"

const Select = ({ title, selected, setActiveCategory }) => {
    const options = ["Food", "Drinks", "Others"]

    return (
        <div className="custom-select d-flex flex-column">
            <label className="title">{title}</label>
            {options.map(o => (
                <div key={o} className="dropdown-item radio" onClick={() => setActiveCategory(o)}>
                    <div className={`radio-select ${selected === o ? "active" : ""}`}></div>
                    <label className={`ps-2 ${selected === o ? "active" : ""}`}>{o}</label>
                </div>
            ))}
        </div>
    )
}

Select.propTypes = {
    title: PropTypes.string.isRequired,
    selected: PropTypes.string,
    setActiveCategory: PropTypes.func.isRequired
}

export default Select
