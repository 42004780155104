import React from "react"
import ConfigurationContent from "../components/configuration/configurationContent"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { withAuth } from "../hoc/withAuth"

const Configuration = withAuth(() => {
    return (
        <Layout>
            <Seo title="Configuration" />
            <NavBar whiteNav bookNow={false} />

            <div className="top-gradient-container">
                <ConfigurationContent />
            </div>

            <Footer />
        </Layout>
    )
})

export default Configuration
