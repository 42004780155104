import React, { useState } from "react"
import { Form, OverlayTrigger, Popover } from "react-bootstrap"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useWindowWidth } from "@react-hook/window-size"

const Input = ({
    header = true,
    className,
    required,
    name,
    placeholder,
    value,
    dimension,
    title,
    type,
    onChange,
    inputStyle = {},
    popoverContent,
    notValid
}) => {
    const InputPopover = ({ name, popoverContent }) => {
        const [show, setShow] = useState(false)
        const windowSize = useWindowWidth()

        return (
            <OverlayTrigger
                // trigger="click"
                rootClose
                show={show}
                placement={windowSize < 1200 ? "bottom" : "right"}
                overlay={
                    <Popover id={name}>
                        <Popover.Body onMouseLeave={() => setShow(false)}>{popoverContent}</Popover.Body>
                    </Popover>
                }
            >
                <p onClick={() => setShow(!show)} onMouseEnter={() => setShow(true)} role="button" className="ms-2">
                    <FontAwesomeIcon className="ms-1 text-lightblue" icon="fa-solid fa-circle-info" />
                </p>
            </OverlayTrigger>
        )
    }

    const onChangeInput = e => {
        if (!name) {
            onChange(e)
        }
        onChange(e, name)
    }
    return (
        <div className={`custom-input position-relative d-flex flex-column ${className ?? ""} ${notValid ? " not-valid" : ""}`}>
            <div className="custom-input__header-wrapper">
                {header && title && <div className={required ? "required name" : "name"}>{title}</div>}
                {popoverContent && <InputPopover name={name} popoverContent={popoverContent} />}
            </div>

            <Form.Control style={inputStyle} placeholder={placeholder} value={value} type={type} onChange={onChangeInput} />
            {dimension && <span>{dimension}</span>}
            {notValid && <p className="not-valid-text">{notValid}</p>}
        </div>
    )
}

Input.propTypes = {
    header: PropTypes.bool,
    className: PropTypes.string,
    required: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dimension: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    inputStyle: PropTypes.object
}

export default Input
